// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/password-show.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changepassword-content label{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.changepasslabel{\r\n  font-family: 'Avenir-Black';\r\n  color:#4C9076;\r\n}\r\n.password-field {\r\n    position: relative;\r\n    max-width: 320px;\r\n}\r\n.password-field .toggleBtn {\r\n    position: absolute;\r\n    right: 15px;\r\n    top: 13px;\r\n    font-size: 0px;\r\n}\r\n.password-field input[type=\"text\"] + .toggleBtn{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/shared/styles/changepassword.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;EAC3B,aAAa;AACf;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,cAAc;AAClB;AACA;EACE,6DAAyD;AAC3D","sourcesContent":[".changepassword-content label{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.changepasslabel{\r\n  font-family: 'Avenir-Black';\r\n  color:#4C9076;\r\n}\r\n.password-field {\r\n    position: relative;\r\n    max-width: 320px;\r\n}\r\n.password-field .toggleBtn {\r\n    position: absolute;\r\n    right: 15px;\r\n    top: 13px;\r\n    font-size: 0px;\r\n}\r\n.password-field input[type=\"text\"] + .toggleBtn{\r\n  background: url(../../images/password-show.svg) no-repeat;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
