// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/employee--master-banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".employee-master-banner{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n}\r\n.employee-master-banner h1{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.employee-lists h2{\r\n    font-family: 'Avenir-Black';\r\n}\r\n@media screen and (max-width : 1023px){\r\n  .employee-master-banner h1{\r\n    font-size: 38px;\r\n  }\r\n}\r\n@media screen and (max-width : 767px){\r\n  .employee-master-banner h1{\r\n        font-size: 30px;\r\n        line-height: 32px;\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/shared/styles/employeemaster.css"],"names":[],"mappings":"AAAA;EACE,6DAAmE;AACrE;AACA;EACE,2BAA2B;AAC7B;AACA;IACI,2BAA2B;AAC/B;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;QACM,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".employee-master-banner{\r\n  background: url(../../images/employee--master-banner.jpg) no-repeat;\r\n}\r\n.employee-master-banner h1{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.employee-lists h2{\r\n    font-family: 'Avenir-Black';\r\n}\r\n@media screen and (max-width : 1023px){\r\n  .employee-master-banner h1{\r\n    font-size: 38px;\r\n  }\r\n}\r\n@media screen and (max-width : 767px){\r\n  .employee-master-banner h1{\r\n        font-size: 30px;\r\n        line-height: 32px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
